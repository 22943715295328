<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Custom Permission Detail</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Custom Permission Detail</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="saveItem" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link
                class="btn btn-info"
                :to="{ name: 'CustomPermissionDetailList' }"
              ><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="List" name="list">
                <div class="col-sm-12">
                  <div class="form-group required">
                    <label for="custom_permission_id" class="control-label">Permission name</label>
                    <multiselect
                      v-model="item.custom_permission_id"
                      :options="all_custom_permissions.map((dept) => dept.id)"
                      :custom-label="(opt) =>all_custom_permissions.find(
                        (x) => x.id == opt).permission_name"
                      placeholder="Pick permission"
                      :searchable="true"
                      :multiple="false"
                      v-validate="'required'"
                      :class="{ 'has-error': errors.has('custom_permission_id') }"
                      id="custom_permission_id"
                      name="custom_permission_id"
                    ></multiselect>
                    <div class="help text-danger" v-show="errors.has('custom_permission_id')">
                      {{ errors.first("custom_permission_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label for="route_id" class="control-label">Route name</label>
                    <multiselect
                      v-model="item.route_id"
                      :options="all_routes.map((dept) => dept.id)"
                      :custom-label="(opt) => all_routes.find((x) => x.id == opt).route_name"
                      placeholder="Pick route name"
                      :searchable="true"
                      :multiple="false"
                      v-validate="'required'"
                      :class="{ 'has-error': errors.has('route_id') }"
                      id="route_id"
                      name="route_id"
                    ></multiselect>
                    <div class="help text-danger" v-show="errors.has('route_id')">
                      {{ errors.first("route_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label for="action_type" class="control-label"
                    >Action Type</label
                    >
                    <div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            id="action_type"
                            type="checkbox"
                            v-model="item.action_type"
                            class="form-check-input"
                            value="List"
                          />List
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            v-model="item.action_type"
                            class="form-check-input"
                            value="Create"
                          />Create
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            v-model="item.action_type"
                            class="form-check-input"
                            value="Edit"
                          />Edit
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            v-model="item.action_type"
                            class="form-check-input"
                            value="View"
                          />View
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            v-model="item.action_type"
                            class="form-check-input"
                            value="Delete"
                          />Delete
                        </label>
                      </div>
                    </div>
                    <div
                      class="help text-danger"
                      v-show="errors.has('action_type')"
                    >
                      {{ errors.first("action_type") }}
                    </div>
                  </div>
                </div>
                <RefIdList :list_refid_condition="item.list_refid_condition"
                           @updateRefIdList="updateRefIdList"/>
                <div class="clearfix"></div>
                <CustomList :list_custom_condition="item.list_custom_condition"
                            @updateCustomList="updateCustomList"/>
              </el-tab-pane>
              <el-tab-pane label="Create" name="create">
                <IDCreate :create_id_condition="item.create_id_condition"
                          :field_list="item.field_list"
                          :form_field_list="item.form_field_list"
                          @updateIDCreate="updateIDCreate"/>
              </el-tab-pane>
              <el-tab-pane label="Edit" name="edit">
                <IDEdit :edit_id_condition="item.edit_id_condition"
                        @updateIDEdit="updateIDEdit"/>
                <div class="clearfix"></div>
                <RefIdEdit :edit_refid_condition="item.edit_refid_condition"
                           @updateRefIdEdit="updateRefIdEdit"/>
                <div class="clearfix"></div>
                <CustomEdit :edit_custom_condition="item.edit_custom_condition"
                            @updateCustomEdit="updateCustomEdit"/>
              </el-tab-pane>
              <el-tab-pane label="Delete" name="delete">
                <IDDelete :delete_id_condition="item.delete_id_condition"
                          @updateIDDelete="updateIDDelete"/>
                <div class="clearfix"></div>
                <RefIdDelete :delete_refid_condition="item.delete_refid_condition"
                             @updateRefIdDelete="updateRefIdDelete"/>
                <div class="clearfix"></div>
                <CustomDelete :delete_custom_condition="item.delete_custom_condition"
                              @updateCustomDelete="updateCustomDelete"/>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              v-if="is_edit === false"
              class="btn btn-primary"
            >
              Submit
            </button>
            <button
              type="submit"
              v-if="is_edit === true"
              class="btn btn-primary"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import {
  RefIdList,
  CustomList,
  IDCreate,
  IDEdit,
  CustomEdit,
  IDDelete,
  CustomDelete,
  RefIdDelete,
} from "./Components";

import {
  RouteListAPI,
  CustomPermissionAPI,
  CustomRolesPermissionDetailAPI,
} from "@/services/api";
import RefIdEdit from "./Components/RefIdEdit";

export default {
  name: "CustomRolesPermissionDetailForm",
  components: {
    RefIdEdit,
    RefIdList,
    CustomList,
    IDCreate,
    IDEdit,
    CustomEdit,
    IDDelete,
    CustomDelete,
    RefIdDelete,
  },
  data: () => ({
    params: "",
    item: {
      list_refid_condition: [],
      list_custom_condition: [],
      create_id_condition: [],
      edit_id_condition: [],
      edit_refid_condition: [],
      edit_custom_condition: [],
      delete_id_condition: [],
      delete_refid_condition: [],
      delete_custom_condition: [],
      action_type: [],
      field_list: '',
      form_field_list: '',
    },
    all_routes: [],
    all_custom_permissions: [],
    is_edit: false,
    is_loading: false,
    activeName: "list",
  }),
  methods: {
    saveItem: function (e) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.is_edit) {
            CustomRolesPermissionDetailAPI.update(this.params.id, this.item)
              .then((response) => {
                this.$router.push({ name: "CustomPermissionDetailList" });
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          } else {
            CustomRolesPermissionDetailAPI.store(this.item)
              .then((response) => {
                this.$router.push({name: "CustomPermissionDetailList"});
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data);
              });
          }
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    updateRefIdList(value) {
      this.item.list_refid_condition = value;
    },
    updateCustomList(value) {
      this.item.list_custom_condition = value;
    },
    updateIDCreate(value) {
      this.item.create_id_condition = value.rows;
      this.item.field_list = value.chile_field_list;
      this.item.form_field_list = value.child_form_field_list;
    },
    updateIDEdit(value) {
      this.item.edit_id_condition = value;
    },
    updateRefIdEdit(value) {
      this.item.edit_refid_condition = value;
    },
    updateCustomEdit(value) {
      this.item.edit_custom_condition = value;
    },
    updateIDDelete(value) {
      this.item.delete_id_condition = value;
    },
    updateRefIdDelete(value) {
      this.item.delete_refid_condition = value;
    },
    updateCustomDelete(value) {
      this.item.delete_custom_condition = value;
    },

    async itemDetail(id) {
      let _this = this
      await CustomRolesPermissionDetailAPI.show(id)
        .then((response) => {
          _this.item = response;
        })
    },
    async allRouteList() {
      await RouteListAPI.allRouteList().then((response) => {
        this.all_routes = response.data;
      });
    },
    async allCustomPermissions() {
      await CustomPermissionAPI.allCustomPermissions().then((response) => {
        this.all_custom_permissions = response.data;
      });
    },
    async getInitialData() {
      let _this = this;
      _this.is_loading = true;
      await _this.allRouteList();
      await _this.allCustomPermissions();
      _this.params = _this.$route.params;
      if (!_.isEmpty(_this.params) && _this.params.id !== undefined) {
        _this.is_edit = true;
      }
      if (_this.is_edit === true) {
        await _this.itemDetail(_this.params.id);
      }
      _this.is_loading = false;
    },
  },
  async mounted () {
    await this.getInitialData();
  },
  updated() {

  }
};
</script>
