import { render, staticRenderFns } from "./CustomList.vue?vue&type=template&id=3770d5fc"
import script from "./CustomList.vue?vue&type=script&lang=js"
export * from "./CustomList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports