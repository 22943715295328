<template>
  <div>
    <div class="col-md-12">
      <h4 class="site-heading my-3">
        <span>ID(Create)</span>
      </h4>
    </div>
    <div class="form-row">
      <div class="col-sm-12">
        <table
          class="table table-bordered builder-table has-aggregate-function"
        >
          <thead>
            <th>Select ID</th>
            <th>Condition Operator</th>
            <th>Condition Value</th>
            <th>Joining Rule</th>
            <th style="width: 50px">Action</th>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" :key="index">
              <td>
                <select class="form-control" v-model="row.condition_field">
                  <option
                    :value="condition_field.key"
                    v-for="condition_field in conditionFields"
                  >
                    {{ condition_field.value }}
                  </option>
                </select>
              </td>
              <td>
                <select class="form-control" v-model="row.condition_op">
                  <option
                    :value="condition_op.key"
                    v-for="condition_op in conditionOperators"
                  >
                    {{ condition_op.value }}
                  </option>
                </select>
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  v-model="row.condition_value"
                />
              </td>
              <td style="text-align: center">
                <select class="form-control" v-model="row.joining_rules">
                  <option :value="joining.key" v-for="joining in joiningRules">
                    {{ joining.value }}
                  </option>
                </select>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="deleteRow(index)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="button"
          @click="addRow"
          class="btn btn-success btn-sm float-right mt-2"
        >
          <i class="fa fa-plus"></i>&nbsp;New
        </button>
      </div>
      <div class="col-md-12">
        <hr />
        <div class="form-group required">
          <label for="child_field_list" class="control-label">Enable Field</label>
          <input
            type="text"
            id="child_field_list"
            name="child_field_list"
            v-model="child_field_list"
            class="form-control"
            :class="{ 'has-error': errors.has('child_field_list') }"
            placeholder="Field list"
          />
          <div class="help text-danger" v-show="errors.has('child_field_list')">
            {{ errors.first("child_field_list") }}
          </div>
        </div>
        <div class="form-group required">
          <label for="child_form_field_list" class="control-label"
            >Enable Form Field</label
          >
          <input
            type="text"
            id="child_form_field_list"
            name="child_form_field_list"
            v-model="child_form_field_list"
            class="form-control"
            :class="{ 'has-error': errors.has('child_form_field_list') }"
            placeholder="Form field list"
          />
          <div class="help text-danger" v-show="errors.has('child_form_field_list')">
            {{ errors.first("child_form_field_list") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
  props: ['create_id_condition', 'field_list', 'form_field_list'],
  computed: {
    ...mapGetters([
      "conditionFields",
      "conditionValueAuth",
      "conditionOperators",
      "joiningRules",
    ]),
  },
  data: () => ({
    rows: [],
    child_field_list: "",
    child_form_field_list: "",
  }),
  components: { Multiselect },
  methods: {
    addRow() {
      this.rows.push({
        condition_field: "",
        condition_op: "",
        joining_rules: "",
        condition_value: "",
      });
    },
    deleteRow: function (index) {
      this.rows.splice(index, 1);
    },
  },
  watch: {
    create_id_condition: function (val, oldVal) {
      this.rows = val;
    },
    field_list: function (val, oldVal) {
      this.child_field_list = val;
    },
    form_field_list: function (val, oldVal) {
      this.child_form_field_list = val;
    },
  },
  updated() {
    this.$emit("updateIDCreate", this.$data);
  },
};
</script>
<style></style>
